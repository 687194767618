import { ref, watch } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

import { getCurrentTimezone } from '@/@core/utils/filter'
import { apiUtil } from '@/api'

import useToast from '@useToast'

export default function useListHandle() {
  const { toastError } = useToast()

  const filterBlank = {
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
  }
  // Loading
  const loading = ref(true)

  const filter = ref(cloneDeep(filterBlank))
  const items = ref([])

  async function fetchItem(isClearFilter) {
    if (isClearFilter) {
      filter.value = cloneDeep(filterBlank)
    }
    try {
      loading.value = true
      const { data } = await apiUtil.getFlightCalendar(filter.value)
      items.value = data.filter(e => e.flights.length > 0).map(e => ({
        date: e.date,
        flights: e.flights
          .filter(flight => {
            // Lọc flights có itineraries hợp lệ
            flight.itineraries = flight.itineraries.filter(itinerary => {
              // Giữ lại các segment có departure.at bắt đầu bằng targetDate
              const filteredSegments = itinerary.filter(({ departure }) => {
                const departureTime = moment.utc(departure.at).utcOffset(departure.timeZone)
                const targetDate = moment.utc(e.date).utcOffset(getCurrentTimezone())
                return departureTime.isSame(targetDate, 'day')
              })

              // Cập nhật itinerary hoặc xóa nếu không hợp lệ
              if (filteredSegments.length > 0) {
                // Nếu có segment hợp lệ, cập nhật itinerary
                return true
              }
              // Xóa ve nếu không còn segment hợp lệ
              return false
            })
            // Giữ lại flights chỉ nếu còn itineraries hợp lệ
            return flight.itineraries.length > 0
          })
          .sort((a, b) => {
            // Lấy thời gian departure đầu tiên của mỗi flight
            const timeA = moment(a.itineraries[0]?.[0]?.departure?.at)
            const timeB = moment(b.itineraries[0]?.[0]?.departure?.at)

            if (!timeA.isValid() || !timeB.isValid()) {
              return 0 // Không thay đổi thứ tự nếu thời gian không hợp lệ
            }

            // Sắp xếp theo thời gian departure
            return timeA.diff(timeB)
          }),
        borderColor: 'transparent',
        backgroundColor: '#FDE9EA',
        textColor: '#2B95D0',
      }))
      loading.value = false
    } catch (error) {
      toastError(error)
    }
  }

  watch(
    [filter],
    () => {
      fetchItem()
    },
    { deep: true },
  )

  return {
    // Loading
    loading,
    filter,
    items,
    // Functions
    fetchItem,
  }
}
