var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "d-flex gap-2"
  }, [_c('span', {
    staticClass: "bg-grey px-1 rounded-pill gap-2 d-flex align-items-center"
  }, [_c('b-button', {
    staticClass: "rounded-circle border border-dark",
    staticStyle: {
      "width": "25px",
      "height": "25px",
      "padding": "0"
    },
    attrs: {
      "variant": "flat-dark",
      "size": "sm",
      "disabled": _vm.month == 1
    },
    on: {
      "click": function click($event) {
        _vm.month--;
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "chevronLeftOutline",
      "size": "18"
    }
  })], 1), _c('div', {
    staticStyle: {
      "width": "100px"
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    attrs: {
      "id": "selectMonth",
      "options": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      "reduce": function reduce(data) {
        return data;
      },
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "text-center mx-auto"
        }, [_vm._v(" " + _vm._s(_vm.getMonthText(data.label)) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(_vm.getMonthText(data.label)) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.month,
      callback: function callback($$v) {
        _vm.month = $$v;
      },
      expression: "month"
    }
  })], 1), _c('b-button', {
    staticClass: "rounded-circle border border-dark",
    staticStyle: {
      "width": "25px",
      "height": "25px",
      "padding": "0"
    },
    attrs: {
      "variant": "flat-dark",
      "size": "sm",
      "disabled": _vm.month == 12
    },
    on: {
      "click": function click($event) {
        _vm.month++;
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "chevronRightOutline",
      "size": "18"
    }
  })], 1)], 1), _c('span', {
    staticClass: "bg-grey px-1 rounded-pill gap-2 d-flex align-items-center"
  }, [_c('b-button', {
    staticClass: "rounded-circle border border-dark",
    staticStyle: {
      "width": "25px",
      "height": "25px",
      "padding": "0"
    },
    attrs: {
      "variant": "flat-dark",
      "size": "sm",
      "disabled": false
    },
    on: {
      "click": function click($event) {
        _vm.year--;
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "chevronLeftOutline",
      "size": "18"
    }
  })], 1), _c('v-select', {
    staticStyle: {
      "min-width": "70px"
    },
    attrs: {
      "id": "selectYear",
      "options": _vm.yearOptions,
      "reduce": function reduce(data) {
        return data;
      },
      "getOptionLabel": function getOptionLabel(data) {
        return data;
      },
      "no-options": _vm.$t('noOptions'),
      "clearable": false
    },
    model: {
      value: _vm.year,
      callback: function callback($$v) {
        _vm.year = $$v;
      },
      expression: "year"
    }
  }), _c('b-button', {
    staticClass: "rounded-circle border border-dark",
    staticStyle: {
      "width": "25px",
      "height": "25px",
      "padding": "0"
    },
    attrs: {
      "variant": "flat-dark",
      "size": "sm",
      "disabled": false
    },
    on: {
      "click": function click($event) {
        _vm.year++;
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "chevronRightOutline",
      "size": "18"
    }
  })], 1)], 1)]), _c('FullCalendar', {
    ref: "refCalendar",
    attrs: {
      "id": "refCalendar",
      "options": _vm.calendarOptions
    },
    scopedSlots: _vm._u([{
      key: "eventContent",
      fn: function fn(arg) {
        return [arg.event.extendedProps.flights.length > 0 ? _c('div', {
          staticClass: "d-flex",
          staticStyle: {
            "padding": "5px 10px"
          }
        }, [_c('div', {
          staticClass: "text-danger font-weight-bolder d-flex gap-1 align-items-center",
          staticStyle: {
            "overflow-x": "hidden"
          }
        }, [_c('div', {
          staticClass: "bg-danger rounded-circle d-flex-center",
          staticStyle: {
            "width": "25px",
            "height": "25px"
          }
        }, [_c('strong', {
          staticClass: "text-white"
        }, [_vm._v(" " + _vm._s(arg.event.extendedProps.flights.length) + " ")])]), _c('IAmIcon', {
          staticClass: "text-danger",
          attrs: {
            "icon": "sentOutline",
            "size": "18"
          }
        }), _c('div', {
          staticClass: "d-none d-xl-block"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.flight')) + " ")])], 1)]) : _vm._e()];
      }
    }])
  })], 1), _vm.itemsByFilter.length > 0 ? _c('b-card', _vm._l(_vm.itemsByFilter, function (item) {
    return _c('div', {
      key: item.date
    }, [item.flights.length ? [_c('div', {
      staticClass: "font-weight-bolder",
      staticStyle: {
        "font-size": "20px"
      }
    }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.date, _vm.getCurrentTimezone(), _vm.locale).fullDateStr) + " ")]), _c('div', {
      staticClass: "text-danger mb-1"
    }, [_vm._v(" " + _vm._s(_vm.$t('flight.noteCalendar')) + " ")]), _vm._l(item.flights, function (flight, index) {
      return _c('b-link', {
        key: index,
        staticClass: "d-flex flex-wrap cursor-pointer flight",
        attrs: {
          "target": "_blank",
          "to": {
            name: 'apps-reservations-modify',
            params: {
              id: flight.id
            }
          }
        }
      }, [_vm._l(flight.itineraries, function (itinerary, itiIndex) {
        var _vm$first, _vm$last, _vm$last$arrival;
        return [_c('div', {
          key: itiIndex,
          staticClass: "d-flex cursor-pointer flight p-0"
        }, [_c('span', {
          staticClass: "mr-1 pr-1 font-weight-bolder border-right text-danger"
        }, [_vm._v(_vm._s(_vm.convertISODateTime((_vm$first = _vm.first(itinerary)) === null || _vm$first === void 0 ? void 0 : _vm$first.departure.at).time))]), _c('div', {
          staticClass: "d-flex gap-1 flex-column"
        }, [_c('div', {
          staticClass: "text-danger font-weight-bolder d-flex gap-1 align-items-center flex-wrap"
        }, [_c('div', {
          staticClass: "bg-danger rounded-circle d-flex align-items-center justify-content-center",
          staticStyle: {
            "width": "25px",
            "height": "25px"
          }
        }, [_c('IAmIcon', {
          staticClass: "text-white",
          attrs: {
            "icon": "sentOutline",
            "size": "18"
          }
        })], 1), _vm._v(" " + _vm._s(_vm.$t('reservation.flightTo')) + " " + _vm._s((_vm$last = _vm.last(itinerary)) === null || _vm$last === void 0 ? void 0 : (_vm$last$arrival = _vm$last.arrival) === null || _vm$last$arrival === void 0 ? void 0 : _vm$last$arrival.city) + " "), _vm._l(itinerary, function (ff, ind) {
          return _c('span', {
            key: ind
          }, [_vm._v(" (" + _vm._s(ff.airline) + _vm._s(ff.flightNumber) + ") ")]);
        })], 2), _vm._l(itinerary, function (cc, ind) {
          return _c('div', {
            key: ind,
            staticClass: "d-flex flex-column flex-md-row gap-2 text-dark"
          }, [_c('div', {
            staticClass: "d-flex text-dark gap-2"
          }, [_c('strong', [_vm._v(_vm._s(flight.bookingCode))]), _c('span', [_vm._v(_vm._s(_vm.convertISODateTime(cc.departure.at, cc.departure.timeZone).time) + "-" + _vm._s(_vm.convertISODateTime(cc.arrival.at, cc.arrival.timeZone).time) + " " + _vm._s(_vm.convertISODateTime(cc.departure.at, cc.departure.timeZone).dayMonth))]), _c('span', [_vm._v(_vm._s("".concat(cc.departure.iataCode).concat(cc.arrival.iataCode)))])]), _c('span', [_vm._v(_vm._s("".concat(flight.paxLists[0].lastName, " ").concat(flight.paxLists[0].firstName)) + " " + _vm._s("".concat(flight.paxLists.length > 1 ? "+".concat(flight.paxLists.length - 1) : ' ')))])]);
        })], 2)])];
      })], 2);
    })] : _vm._e()], 2);
  }), 0) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }